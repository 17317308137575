import React, { Fragment } from 'react'

import { RadioGroup, ModalSectionLabel, WarningMessage } from 'Components'
import { colors, plurals, formatNumber } from 'Utils'

import {
  Input as AntInput,
  Row,
  Col,
  Popover,
  InputNumber,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

const year = new Date().getFullYear();

export const ModalFormHowToMeasure = ({
  options,
  measureType,
  setMeasureType,
  valueDistribution,
  setValueDistribution,
  refValue,
  setRefValue,
  refValueType,
  setRefValueType,
  showWarning,
  refAno,
  setRefAno,
  measureId,
  measureUd,
  euroTypeValue,
  euroTypeDesc,
  euroTypeSettable,
  setEuroTypeSettable,
  euroTypeMinValue,
  setEuroTypeMinValue,
  euroTypeMinPayment,
  setEuroTypeMinPayment,
  nArtGroup,
  setNArtGroup,
  canales
}) => {
  const desType = measureType === 'E' ? euroTypeValue : plurals(measureUd, refValue > 1)

  const filterMeasure = (options) => {
    let newList = [];

    newList = options.filter(opt => (measureId !== 'U') ? opt.id !== 'E' : opt.id !== '')

    if (measureId === 'A') {
      newList = newList.filter(opt => opt.id === 'C');
    }
    if (measureId === 'S') {
      newList = newList.filter(opt => opt.id === 'SPA' || opt.id === 'SPG');
    }
    if (measureId !== 'S' && measureId !== 'A') {
      newList = newList.filter(opt => opt.id !== 'SPG' && opt.id !== 'C');
    }

    if(canales.length > 0){
      newList = newList.filter((opt => ( opt.id !== 'IA')))
    }
   
    return newList;
  }

  return (
    <Fragment>
      {measureId !== 'S' && (
        <>
          <ModalSectionLabel>
            {measureUd !== 'libre' ? 'Tipo de medida' : '¿Cómo quieres medirlo?'}
          </ModalSectionLabel>
          <RadioGroup
            options={filterMeasure(options.type)}
            value={(measureType === '' && measureId === 'A') ? 'C' : (measureType === '' && measureId === 'S') ? 'SPG' : measureType}
            onChange={e => {
              if (e.target.value === 'T') {
                setRefValueType('cantidad')
              }
              setMeasureType(e.target.value)
            }}
            label={option => option.id === 'IA'?
              (
                <span>
                  {option.desc}
                  <InputNumber
                    defaultValue={refAno}
                    style={{ width: "70px", marginLeft: "10px" }}
                    onChange={e => {
                      setRefAno(e)
                    }}
                    min={year - 2}
                    max={year - 1}
                  />
                </span>
              ) : option.id === 'E' ? (<span>{option.desc}{plurals(measureUd)}</span>) : (<span>{option.desc}</span>)
            }
          ></RadioGroup>
        </>
      )}
      {measureId !== 'A' && (
        <>
          <ModalSectionLabel>
            {measureType === 'E' ? 'Incentivo' : 'Valor de referencia'}
            <Popover
              content={
                measureType === 'E' ? (<span>
                  {euroTypeDesc}
                </span>) : (<span>
                  Para decrecimientos, el valor <br />
                  de referencia debe ser <br /> negativo.
                </span>)
              }
            >
              <InfoIcon />
            </Popover>
          </ModalSectionLabel>
          <Row type="flex">
            <Col span={8}>
              <Input
                value={formatNumber(
                  refValue.toString(),
                  (measureType === 'E' || (measureType === 'IA' && refValueType === 'porcentaje')),
                )}
                onChange={e =>
                  setRefValue(
                    formatNumber(e.target.value, (measureType === 'E' || (measureType === 'IA' && refValueType === 'porcentaje'))),
                  )
                }
              ></Input>
            </Col>
            <Col span={15} push={1}>
              {(measureType === 'IA') ? (
                <RadioGroup
                  value={refValueType}
                  onChange={e => setRefValueType(e.target.value)}
                  type="buttons"
                  options={[
                    {
                      desc: desType,
                      id: 'cantidad',
                    },
                    {
                      desc: '%',
                      id: 'porcentaje',
                    },
                  ]}
                  label={option => <span>{option.desc}</span>}
                ></RadioGroup>
              ) : <RefValue>{desType}</RefValue>}
            </Col>
          </Row>
        </>
      )}
      {
        measureType === 'E' &&
        <>
          <ModalSectionLabel> ¿Existe un número Mínimo de {plurals(measureUd, true)} para conseguir incentivo? </ModalSectionLabel>
          <Row type="flex">
            <Col span={9} push={1}>
              <RadioGroup
                value={euroTypeSettable}
                onChange={e => setEuroTypeSettable(e.target.value)}
                type="buttons"
                options={[
                  {
                    desc: 'No',
                    id: false,
                  },
                  {
                    desc: 'Sí',
                    id: true,
                  },
                ]}
                label={option => <span>{option.desc}</span>}
              ></RadioGroup>
            </Col>
            {euroTypeSettable && (<>
              <Col span={8}>
                <Input
                  value={euroTypeMinValue}
                  onChange={e =>
                    setEuroTypeMinValue(e.target.value)
                  }
                ></Input>
              </Col>
              <Col span={5} push={1}>
                <RefValue>{plurals(measureUd, euroTypeMinValue > 1)}</RefValue>
              </Col>
            </>)}
          </Row>
          {euroTypeSettable && (
            <>
              <ModalSectionLabel>
                Pago de incentivo a partir de
              </ModalSectionLabel>
              <RadioGroup
                value={euroTypeMinPayment}
                onChange={e => setEuroTypeMinPayment(e.target.value)}
                options={[
                  {
                    desc: 'Cantidad mínima',
                    id: false,
                  },
                  {
                    desc: `La primera ${plurals(measureUd)}`,
                    id: true,
                  },
                ]}
                label={option => <span>{option.desc}</span>}
              ></RadioGroup>
            </>
          )}
        </>
      }
      {measureId === 'S' && (
        <>
          <ModalSectionLabel>
            Tipo de saturación
          </ModalSectionLabel>
          <RadioGroup
            options={filterMeasure(options.type)}
            value={measureType === '' ? 'SPG' : measureType}
            onChange={e => {
              setMeasureType(e.target.value)
            }}
            label={option => (<span>{option.desc}</span>)
            }
          ></RadioGroup>
          <ModalSectionLabel>
            Número mínimo de {measureType === 'SPA' ? 'artículos' : 'grupos'} por PdV
          </ModalSectionLabel>
          <Row type="flex">
            <Col span={8}>
              <InputNumber
                value={parseInt(nArtGroup)}
                // max={measureType && measureType === 'SPG' ? 10 : 20}
                max={10}
                min={2}
                onChange={e => setNArtGroup(e)}
              ></InputNumber>
            </Col>
            <Col span={15} push={1}>
              <RefValue>{measureType === 'SPA' ? 'Artículos' : 'Grupos'}</RefValue>
            </Col>
          </Row>
        </>
      )}
      <ModalSectionLabel>
        {measureUd !== 'libre' ? 'Distribución del valor de referencia' : '¿A quién aplica?'}
      </ModalSectionLabel>
      <RadioGroup
        options={options.distribution}
        value={valueDistribution}
        onChange={e => setValueDistribution(e.target.value)}
        label={option => (
          <span style={{ fontSize: 16, color: '#555' }}>
            {option.desc}
            <br />
            <SmallExplanation>{option.nota}</SmallExplanation>
          </span>
        )}
      ></RadioGroup>
      {showWarning && (
        <WarningMessage>
          <span>
            Si se modifican estos valores se{' '}
            <strong>eliminarán los ajustes del objetivo</strong>
          </span>
        </WarningMessage>
      )}
    </Fragment>
  )
}

const InfoIcon = styled(InfoCircleOutlined)`
  vertical-align: middle;
  font-size: 12px;
  margin-left: 3px;
`
const RefValue = styled.span`
  line-height: 32px;
`
const Input = styled(AntInput)`
  text-align: right;
`
const SmallExplanation = styled.small`
  font-size: 14px;
  color: ${colors.warmGrey};
  padding-left: 35px;
  display: block;
  white-space: normal;
`