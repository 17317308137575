import React from "react";
import {
  FlexCol,
  FlexRow,
  ObjectiveDetailLabel,
  ObjectiveDetailDescription,
  ObjectiveDetail,
} from "./shared";

import {
  Collapse,
  CollapseHeader,
  ObjectiveButtonList,
  Panel,
} from "Components";

import moment from 'moment';

export const Dealer = ({ dealer, setProps, editDealer = () => {} }) => {
  const {
    codigoDistribuidor,
    cif,
    razonSocial,
    modifEn,
    poblacion,
    provincia,
    direccionFiscal,
    email,
    codigoPostal,
    codigoProveedorHesa,
    gerente,
    erp,
    user,
    password,

    observaciones,

    last_login,
    last_transmission_clientes,
    last_transmission_articulos,
    last_transmission_ventas,
    last_transmission_existencias,

    record_type_counts_clientes,
    record_type_counts_articulos,
    record_type_counts_ventas,
    record_type_counts_existencias,
  } = dealer;

  const leftDataSource = [
    { label: "Distribuidor", description: codigoDistribuidor },
    { label: "CIF", description: cif },
    { label: "Razón Social", description: razonSocial },
    { label: "Gerente", description: gerente },
    { label: "E-mail", description: email },
    { label: "ERP", description: erp },
  ];

  const centerDataSource = [
    { label: "Proveedor HESA", description: codigoProveedorHesa },
    { label: "Población", description: poblacion },
    { label: "Provincia", description: provincia },
    { label: "Dirección", description: direccionFiscal },
    { label: "C.P.", description: codigoPostal },
    { label: "Observaciones", description: observaciones },
  ];

  const rightDataSource = [
    { label: "Última transmisión clientes", description: last_transmission_clientes, quantity: record_type_counts_clientes },
    { label: "Última transmisión artículos", description: last_transmission_articulos, quantity: record_type_counts_articulos },
    { label: "Última transmisión ventas", description: last_transmission_ventas, quantity: record_type_counts_ventas },
    { label: "Última transmisión existencias", description: last_transmission_existencias, quantity: record_type_counts_existencias },
  ];

  return (
    <Collapse expandIconPosition="end" onChange={() => {}}>
      <Panel
        header={
          <CollapseHeader
            title={`${razonSocial} - ${codigoDistribuidor}`}
            date={modifEn}
            date2={last_login}
            isDistributor={true}
          />
        }
      >
        <FlexRow>
          <FlexCol span={12} style={{ borderRight: "solid 1px #eaeaea" }}>
            {leftDataSource.map(({ label, description }, index) => (
              <ObjectiveDetail key={`l-${index}`}>
                <ObjectiveDetailLabel>{label}</ObjectiveDetailLabel>
                <ObjectiveDetailDescription>
                  {description ? description : "-"}
                </ObjectiveDetailDescription>
              </ObjectiveDetail>
            ))}
          </FlexCol>
          <FlexCol span={12} style={{ borderRight: "solid 1px #eaeaea" }}>
            {centerDataSource.map(({ label, description }, index) => (
              <ObjectiveDetail key={`c-${index}`}>
                <ObjectiveDetailLabel>{label}</ObjectiveDetailLabel>
                <ObjectiveDetailDescription style={{ wordWrap: 'break-word', maxWidth:'500px' }}>
                  {description ? description : "-"}
                </ObjectiveDetailDescription>
              </ObjectiveDetail>
            ))}
          </FlexCol>
          <FlexCol span={12}>
            {rightDataSource.map(({ label, description, quantity }, index) => (
              <ObjectiveDetail key={`r-${index}`}>
                <ObjectiveDetailLabel>{label}</ObjectiveDetailLabel>
                <ObjectiveDetailDescription>                
                  {(description && description != -62135596800000) ?  `${moment(description).format('DD/MM/YYYY HH:mm:ss')} - ${(quantity>=0)? quantity + ' registros' : 'ERROR'}`: "-"}
                </ObjectiveDetailDescription>
              </ObjectiveDetail>
            ))}
          </FlexCol>
        </FlexRow>
        <ObjectiveButtonList
          onEdit={() =>
            setProps({
              visible: true,
              title: "Editar distribuidor",
              okText: "Editar",
              dataInfo: dealer,
              submit: editDealer,
            })
          }
        />
      </Panel>
    </Collapse>
  );
};
