import React, { useContext, useState, useEffect } from "react";

import { OptionGroupButton } from "Components";
import { ModalFormWhatToMeasure } from "Fragments";
import {
    UPDATE_OBJECTIVE_WHAT,
    OBJECTIVE,
    OPEN_OBJECTIVE,
    GET_PROVIDERS,
    GET_SPECIALISTS,
    GET_CANALES,
} from "Queries";
import {
    GlobalContext,
    ObjectiveContext,
    getMutationVars,
    getObjectiveIdVars,
} from "Utils";

import { get, isNil, isEmpty, without, concat } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

export const OptionGroupButtonWhat = ({
    whatFormOptions,
    whatSalesTypeFormOptions,
    objective,
}) => {
    const context = {
        ...useContext(GlobalContext),
        ...useContext(ObjectiveContext),
    };
    const { resetKeepAliveTimer, setObjective, setRecalculating } = context;

    const onCompleted = ({ objective }) => {
        resetKeepAliveTimer();
        setObjective(objective);
        setRecalculating(false);
    };

    const [updateMeasure] = useMutation(
        UPDATE_OBJECTIVE_WHAT,
        getMutationVars(
            getObjectiveIdVars(context),
            get(objective, "medida.id") === "A" ? OPEN_OBJECTIVE : OBJECTIVE,
            {
                onCompleted,
            }
        )
    );

    const { data: providerData } = useQuery(GET_PROVIDERS, {
        fetchPolicy: "network-only",
        variables: {
            codigoDistribuidor: get(context, "currentUser.codDistribuidor"),
        },
    });
    const providerOptions = providerData
        ? providerData.obtenerProveedorHesa
        : [];

    const { data: specialistData } = useQuery(GET_SPECIALISTS, {
        fetchPolicy: "network-only",
        variables: {
            codDistribuidor: get(context, "currentUser.codDistribuidor"),
        },
    });
    const specialistOptions = specialistData
        ? specialistData.obtenerEspecialistaInformacion
        : [];

    const { data: canalData } = useQuery(GET_CANALES, {
        onCompleted: resetKeepAliveTimer,
    });
    const canalOptions = canalData ? canalData.getCanales : [];

    const medidaId = get(objective, "medida.id");
    const tiposEntrega = (get(objective, "tiposEntrega") || []).map(
        (v) => v.id
    );
    const [paraEspecialistas, setParaEspecialistas] = useState(
        get(objective, "paraEspecialistas") || false
    );
    const [ocultarNiveles, setOcultarNiveles] = useState(
        get(objective, "ocultarNiveles") || false
    );
    const [paraProveedor, setParaProveedor] = useState(
        get(objective, "paraProveedor") || ""
    );
    const [paraCanal, setParaCanal] = useState(get(objective, "canales") || []);
    const [specialistID, setSpecialistID] = useState(
        get(objective, "satEspecialista") || null
    );
    const conExcepciones = get(objective, "withException") || false;

    const [measure, setMeasure] = useState(medidaId);
    const [salesType, setSalesType] = useState(tiposEntrega);
    const [showModal, setShowModal] = useState(false);
    const [withException, setWithException] = useState(conExcepciones);

    const [hasProvider, setHasProvider] = useState(
        get(objective, "paraProveedor") !== null &&
            get(objective, "paraProveedor") !== ""
            ? true
            : false
    );
    const [hasCanal, setHasCanal] = useState(
        get(objective, "canales") !== null &&
            get(objective, "canales") !== "" &&
            get(objective, "canales").length > 0
            ? true
            : false
    );

    const [tipoValorRef, setTipoValorRef] = useState(get(objective, "tipoValorRef") || '');

    useEffect(() => {
        if (objective.medida) setMeasure(objective.medida.id);
    }, [objective.medida]);

    useEffect(() => {
        if (objective.tiposEntrega)
            setSalesType(objective.tiposEntrega.map((v) => v.id));
    }, [objective.tiposEntrega]);

    useEffect(() => {
        if (objective.withException) setWithException(objective.withException);
    }, [objective.withException]);

    useEffect(() => {
        setParaEspecialistas(get(objective, "paraEspecialistas") || false);
        setOcultarNiveles(get(objective, "ocultarNiveles") || false);
        setParaProveedor(get(objective, "paraProveedor") || "");
        setHasProvider(
            get(objective, "paraProveedor") !== null &&
                get(objective, "paraProveedor") !== ""
                ? true
                : false
        );
        setParaCanal(get(objective, "canales") || []);
        setHasCanal(
            get(objective, "canales") !== null &&
                get(objective, "canales") !== "" &&
                get(objective, "canales").length > 0
                ? true
                : false
        );
        setSpecialistID(get(objective, "satEspecialista") || null);
        setTipoValorRef(get(objective,'tipoValorRef') || '')
    }, [showModal, objective]);

    const sublabel =
        (objective.medida &&
            `${get(objective, "medida.desc")} (${get(
                objective,
                "medida.unidad"
            )})`) ||
        "SELECCIONAR";

    const showWarning =
        !isEmpty(objective.distribucionValorRef) &&
        (measure !== medidaId ||
            objective.paraEspecialistas !== paraEspecialistas ||
            (measure === "S" &&
                paraEspecialistas &&
                specialistID !== objective.satEspecialista) ||
            hasProvider !==
                (objective.paraProveedor !== null &&
                    objective.paraProveedor !== "") ||
            (hasProvider && objective.paraProveedor !== paraProveedor) ||
            hasCanal !==
                (objective.paraCanal !== null && objective.paraCanal !== "") ||
            (hasCanal && objective.paraCanal !== paraCanal) ||
            JSON.stringify(tiposEntrega) !== JSON.stringify(salesType) ||
            withException !== conExcepciones);

    const modalProps = {
        visible: showModal ? 1 : 0,
        title: "¿Qué quieres medir?",
        okText: "Seleccionar",
        width: 760,
        okButtonProps: {
            disabled:
                isNil(measure) ||
                (hasProvider &&
                    (paraProveedor === null || paraProveedor === "")) ||
                (hasCanal &&
                    (paraCanal.length === 0 ||
                        paraCanal === null ||
                        paraCanal === "")) ||
                (!isNil(measure) &&
                    measure !== "A" &&
                    (isNil(salesType) || isEmpty(salesType))) ||
                (measure === "S" && paraEspecialistas && specialistID === null),
        },
        onCancel: () => {
            setShowModal(false);
            setMeasure(medidaId);
            setSalesType(tiposEntrega);
            setWithException(conExcepciones);
        },
        onOk: async () => {
            setShowModal(false);
            if (
                measure !== medidaId ||
                objective.paraEspecialistas !== paraEspecialistas ||
                objective.ocultarNiveles !== ocultarNiveles ||
                !hasProvider ||
                (hasProvider && objective.paraProveedor !== paraProveedor) ||
                !hasCanal ||
                (hasCanal && objective.paraCanal !== paraCanal) ||
                JSON.stringify(tiposEntrega) !== JSON.stringify(salesType) ||
                withException !== conExcepciones
            ) {
                setRecalculating(true);
                await updateMeasure({
                    variables: {
                        medida: measure,
                        tipoEntrega: salesType,
                        paraEspecialistas,
                        ocultarNiveles,
                        paraProveedor:
                            hasProvider && paraProveedor !== ""
                                ? paraProveedor
                                : null,
                        satEspecialista:
                            measure === "S" && paraEspecialistas
                                ? specialistID
                                : null,
                        conExcepciones:
                            measure !== "A" && measure !== "S"
                                ? withException
                                : null,

                                canales:
                                hasCanal && paraCanal !== ""
                                    ? paraCanal.map((canal) => {
                                          if (typeof canal === "string") {
                                              return canal;
                                          } else {
                                              return canal.codigoCanal;
                                          }
                                      })
                                    : [],
                    },
                });
            }
        },
    };

    return (
        <>
        <OptionGroupButton
            label="¿Qué quieres medir?"
            sublabel={sublabel}
            selected={!!objective.medida}
            onClick={() => setShowModal(true)}
            formView={() => (
                <ModalFormWhatToMeasure
                    showWarning={showWarning}
                    measure={measure}
                    salesType={salesType}
                    whatMeasureFormOptions={whatFormOptions}
                    whatSalesTypeFormOptions={whatSalesTypeFormOptions}
                    providerOptions={providerOptions}
                    specialistOptions={specialistOptions}
                    onChangeMeasure={setMeasure}
                    onChangeSalesType={(e) => {
                        const id = e.target.value;
                        setSalesType(
                            salesType.indexOf(id) > -1
                                ? without(salesType, id)
                                : concat(salesType, id)
                        );
                    }}
                    emptySalesType={() => {
                        setSalesType([]);
                    }}
                    paraEspecialistas={paraEspecialistas}
                    setParaEspecialistas={setParaEspecialistas}
                    ocultarNiveles={ocultarNiveles}
                    setOcultarNiveles={setOcultarNiveles}
                    hasProvider={hasProvider}
                    setHasProvider={setHasProvider}
                    hasCanal={hasCanal}
                    setHasCanal={setHasCanal}
                    paraProveedor={paraProveedor}
                    specialistID={specialistID}
                    setSpecialistID={setSpecialistID}
                    setParaProveedor={setParaProveedor}
                    setParaCanal={setParaCanal}
                    paraCanal={paraCanal.map((canal) => {
                        if (typeof canal === "string") {
                            return canal;
                        } else {
                            return canal.codigoCanal;
                        }
                    })}
                    withException={withException}
                    setWithException={setWithException}
                    canalOptions={canalOptions}
                    tipoValorRef={tipoValorRef}
                ></ModalFormWhatToMeasure>
            )}
            modalProps={modalProps}
        ></OptionGroupButton>
    </>

    );
};
