import React, { useEffect, useState} from 'react';
import { CSVLink } from "react-csv";
import { Select, DatePicker } from 'antd';
import styled from '@emotion/styled';
import { Button } from 'Components';
import moment from 'moment';

export const Stock = ({distribuidores}) => {
  const [distribuidor, setDistribuidor] = useState();
  const [fecha, setFecha] = useState();
  const [tabla, setTabla] = useState();
  const [ipadResolution, setIpadResolution] = useState();


  // MOCK EXISTENCIA_NEXT_SA
  const existenciaNextSaMock = [
    {
      codigoDistribuidor: 1000000023,
      codigoArticuloProveedor: 'AA',
      fecha: moment(),
      unidades: 10
    },
    {
      codigoDistribuidor: 1000000023,
      codigoArticuloProveedor: 'BBACEF',
      fecha: new Date('2024-12-25 00:00:00').getTime(),
      unidades: 345
    },
    {
      codigoDistribuidor: 1,
      codigoArticuloProveedor: 'E41F',
      fecha: new Date('2024-12-25 00:00:00').getTime(),
      unidades: 5
    }
  ];

  // MOCK EXISTENCIA_NEXT_SADM
  const existenciaNextSadmMock = [
    {
      codigoDistribuidor: 1000000023,
      codigoArticuloProveedor: 'AA'
    },
    {
      codigoDistribuidor: 1000000023,
      codigoArticuloProveedor: 'BBACEF'
    },
    {
      codigoDistribuidor: 1,
      codigoArticuloProveedor: 'V'
    }
  ];

  const tablas = [
    { value: 'EXISTENCIA_NEXT_sa', label: 'EXISTENCIA_NEXT_sa' },
    { value: 'EXISTENCIA_NEXT_sadm', label: 'EXISTENCIA_NEXT_sadm' },
  ];

  const header_EXISTENCIA_NEXT_sa = [
    { label: 'COD_DISTRIBUIDOR', key: 'codigoDistribuidor' },
    { label: 'CODIGO_ARTICULO_PROVEEDOR', key: 'codigoArticuloProveedor' },
    { label: 'FECHA', key: 'fecha' }, 
    { label: 'UNIDADES', key: 'unidades'},
  ]

  const header_EXISTENCIA_NEXT_sadm = [
    { label: 'CODIGO_DISTRIBUIDOR', key: 'codigoDistribuidor' },
    { label: 'CODIGO_ARTICULO_PROVEEDOR', key: 'codigoArticuloProveedor' },
  ]

  const header = tabla === 'EXISTENCIA_NEXT_sa' ? header_EXISTENCIA_NEXT_sa : header_EXISTENCIA_NEXT_sadm;
  const datos = tabla === 'EXISTENCIA_NEXT_sa' ? existenciaNextSaMock : existenciaNextSadmMock;
  const [datosFiltrados, setDatosFiltrados] = useState([]);

  const filtrarDatos = (distribuidor, fecha, tabla, datos) => {

    if (tabla === 'EXISTENCIA_NEXT_sa') {
      return datos.filter(dato => {
        if (distribuidor && dato.codigoDistribuidor !== distribuidor) 
          return false;
        if (fecha && moment(dato.fecha).format('YYYY-MM-DD') !== moment(fecha).format('YYYY-MM-DD')) 
          return false;
        return true;
      });
    } else {
      return datos.filter(dato => {
        if (distribuidor && dato.codigoDistribuidor !== distribuidor) 
          return false;
        return true;
      })
    }
  }

  useEffect(() => {
    handleFiltroChange();
    setTimeout(() => {
      updateResolution()
    }, 100)
    window.addEventListener('resize', updateResolution)
    return () => {
      window.removeEventListener('resize', updateResolution)
    }
  }, [distribuidor, fecha, tabla])

  const handleDistribuidorChange = (value) => {
    setDistribuidor(value);
  };
  
  const handleFechaChange = (date) => {
    setFecha(date);
  };
  
  const handleTablaChange = (value) => {
    setTabla(value);
  };

  const clearFilter = () => {
    setDistribuidor()
    setFecha()
    setTabla()
    setDatosFiltrados();
  }

  const updateResolution = () => {
    setIpadResolution(document.querySelector('#contentWidth').offsetWidth < 965)
  }

  const handleFiltroChange = () => {
    const datosFiltrados = filtrarDatos(distribuidor, fecha, tabla, datos);
    setDatosFiltrados(datosFiltrados);
  }


  return (
    <div>
      {/* <div style={{display: 'flex', alignItems:'center'}}> */}
      <div id="contentWidth" style={{ 
        // position: 'relative', 
        minWidth: '830px' 
      }}>
        <FilterWrapper>
          <Select 
            placeholder="Seleccionar distribuidor"
            value={distribuidor}
            onChange={handleDistribuidorChange}
            style = {
              ipadResolution
                ? {width: 200, minWidth: 120 }
                : { width: 300, minWidth: 120 }
            }
          >
            {distribuidores.map((distribuidor, index) => (
              <Select.Option key={index} value={distribuidor.value}>
                {distribuidor.label}
              </Select.Option>
            ))}
          </Select>
          <DatePicker
            value={fecha}
            onChange={handleFechaChange}
            style = {
              ipadResolution
                ? {width: 150, minWidth: 120 }
                : { width: 220, minWidth: 120 }
            }
          />
          <Select
            value={tabla}
            mode="default"
            placeholder="Seleccionar tabla"
            options={tablas}
            onChange={handleTablaChange}
            style = {
              ipadResolution
                ? {width: 180, minWidth: 120 }
                : { width: 220, minWidth: 120 }
            }
          />
          <Button
            disabled={!distribuidor && !tabla && !fecha}
            type="link"
            size="large"
            onClick={clearFilter}
            style={{
              marginLeft: 2,
              marginRight: 2,
              padding: '0 10px',
              fontSize: 12,
            }}
          >
            Resetear filtros
          </Button>
          <CSVLink
            headers={header}
            data={datosFiltrados.map(dato => ({
              ...dato,
              fecha: dato.fecha? moment(fecha).format('DD/MM/YYYY') : '',
            }))}
            filename={`${distribuidor} - ${tabla} - ${fecha}.csv`}
            className="btn btn-primary"
            target="_blank"
            separator={";"}
          >
            <Button 
              disabled={!distribuidor || ! tabla || !fecha} 
              // loading={loading} 
              type="primary" 
              style={{ 
                marginRight: 10,
                padding: '0 10px',
                fontSize: 12 
              }} 
            >
              Descargar CSV
            </Button>
          </CSVLink>
        </FilterWrapper>
      </div>
      {/* <p>Aquí se mostrará la información de los stocks</p> */}
    </div>
  );
};

const FilterWrapper = styled.div`
  // position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 0px;
  top: 65px;
  > div {
    margin: 0 2px
  }
`