import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import styled from "@emotion/styled";
import { FormLabel, DealerCredentialsForm } from "./";
import { Modal } from "Components";
import TextArea from "antd/lib/input/TextArea";

// eslint-disable-next-line
const emailRE =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const nameRE = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+( [a-zA-ZÀ-ÿ\u00f1\u00d1]+)+$/;

const formFilds = {
  codigoDistribuidor: "",
  codigoProveedorHesa: "",
  codigoEmpleado: "",
  codigoPostal: "",
  cif: "",
  razonSocial: "",
  gerente: "",
  email: "",
  poblacion: "",
  provincia: "",
  direccion: "",
  erp: "",
  user: "",
  password: "",
  observaciones: "",
};

export const AddDealerForm = ({ props, setProps }) => {
  const [dealerForm, setDealerForm] = useState(formFilds);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "Credenciales de transmisión",
    okText: "Confirmar",
    credentials: {
      user: dealerForm.user,
      password: dealerForm.password,
    },
  });

  useEffect(() => {
    setDealerForm(props.dataInfo !== undefined ? props.dataInfo : formFilds);
  }, [props]);

  const afterSubmit = () => {
    setProps({ visible: false });
    setSubmitDisabled(false);
  };

  const updateCredentials = (props) => {
    setDealerForm({
      ...dealerForm,
      user: props.variables.user,
      password: props.variables.password,
    });
  };

  const onSubmit = (_, closeModal = true) => {
    setSubmitDisabled(true);
    const variables = {
      codigoDistribuidor: dealerForm.codigoDistribuidor,
      codigoEmpleado:
        props.okText === "Editar" ? dealerForm.codigoEmpleado : null,
      codigoProveedorHesa: dealerForm.codigoProveedorHesa,
      cif: dealerForm.cif,
      poblacion: dealerForm.poblacion,
      provincia: dealerForm.provincia,
      razonSocial: dealerForm.razonSocial,
      direccionFiscal: dealerForm.direccionFiscal,
      codigoPostal: dealerForm.codigoPostal,
      gerente: dealerForm.gerente,
      email: dealerForm.email,
      erp: dealerForm.erp,
      user: dealerForm.user,
      password: dealerForm.password,
      observaciones: dealerForm.observaciones.replace(/\n/g, ' '),
    };

    return props
      .submit({ variables })
      .then(() => {
        if (closeModal) afterSubmit();
        if (props.okText === "Añadir")
          window.location.reload();
      })
      .catch(() => setSubmitDisabled(false));
  };

  const disableSubmit =
    isSubmitDisabled ||
    !dealerForm.codigoDistribuidor ||
    dealerForm.codigoDistribuidor === "" ||
    !dealerForm.gerente ||
    dealerForm.gerente === "" ||
    !nameRE.test(dealerForm.gerente) ||
    !dealerForm.email ||
    dealerForm.email === "" ||
    !emailRE.test(dealerForm.email) || 
    dealerForm.observaciones.length > 200
    ;

    const ErrorMessage = ({ message }) => {
      return (
        <span style={{ color: 'red' }}>
          {message}
        </span>
      );
    };


  return (
    <Modal
      style={{
        borderLeft: "20px solid #ffffff",
        borderRight: "5px solid #ffffff",
        borderImage: "linear-gradient(to bottom, #008200 0%, #03d503 100%) 1",
      }}
      title={props.title}
      visible={props.visible}
      width={1000}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setDealerForm(formFilds);
            setProps({
              title: props.title,
              subtitle: props.subtitle,
              okText: props.okText,
              visible: false,
            });
          }}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          disabled={disableSubmit}
        >
          {props.okText}
        </Button>,
      ].filter((btn) => btn)}
    >
      <Form layout="vertical">
        <Row>
          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="Distribuidor" />}>
              <Input
                disabled={props.okText === "Editar"}
                value={dealerForm.codigoDistribuidor}
                onChange={(event) => {
                  const { value: inputValue } = event.target;
                  const reg = /^-?\d*(\.\d*)?$/;
                  if (
                    reg.test(inputValue) ||
                    inputValue === "" ||
                    inputValue === "-"
                  ) {
                    setDealerForm({
                      ...dealerForm,
                      codigoDistribuidor: event.target.value,
                    });
                  }
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              label={<FormLabel title="Proveedor HESA" optional={true} />}
            >
              <Input
                disabled={props.okText === "Editar"}
                value={dealerForm.codigoProveedorHesa}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    codigoProveedorHesa: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="E-mail" />}>
              <Input
                // disabled={props.okText === 'Editar'}
                value={dealerForm.email}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    email: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              label={<FormLabel title="Gerente (Nombre y apellidos)" />}
            >
              <Input
                value={dealerForm.gerente}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    gerente: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="CIF" optional={true} />}>
              <Input
                value={dealerForm.cif}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    cif: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              label={<FormLabel title="Razón Social" optional={true} />}
            >
              <Input
                value={dealerForm.razonSocial}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    razonSocial: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="Población" optional={true} />}>
              <Input
                value={dealerForm.poblacion}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    poblacion: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="Provincia" optional={true} />}>
              <Input
                value={dealerForm.provincia}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    provincia: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="Dirección" optional={true} />}>
              <Input
                value={dealerForm.direccionFiscal}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    direccionFiscal: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="C.P." optional={true} />}>
              <Input
                value={dealerForm.codigoPostal}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    codigoPostal: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="ERP" optional={true} />}>
              <Input
                value={dealerForm.erp}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    erp: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem label={<FormLabel title="" />}>
              <Button
                key="credentials"
                type="primary"
                onClick={() =>
                  setModalProps({
                    ...modalProps,
                    visible: true,
                    credentials: {
                      user: dealerForm.user,
                      password: dealerForm.password,
                    },
                    submit: updateCredentials,
                  })
                }
              >
                Credenciales de transmisión
              </Button>
            </FormItem>
            

          </Col>
          <Col xs={24} md={24}>
            <FormItem label={<FormLabel title="Observaciones" optional={true} />}>
              <span style={{
                fontSize: 12, 
                color: 
                  dealerForm.observaciones.length > 200 ? '#8B0A1A' :
                  dealerForm.observaciones.length > 190 ? 'red' : 
                  dealerForm.observaciones.length > 160 ? 'orange' :
                  dealerForm.observaciones.length > 100 ? '#CCCC00' :
                  dealerForm.observaciones.length > 0 ? 'green' : 
                  '#666'
              }}>
                {dealerForm.observaciones?.length} / 200
                {dealerForm.observaciones.length > 200 && (
                  <ErrorMessage message=" - Este campo no puede exceder los 200 caracteres." />
                )}        
              </span>
              <TextArea
                id='observacionesArea'
                value={dealerForm.observaciones}
                onChange={(event) => {
                  setDealerForm({
                    ...dealerForm,
                    observaciones: event.currentTarget.value,
                  });
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <DealerCredentialsForm
        props={modalProps}
        setProps={setModalProps}
        isLoading={false}
      />
    </Modal>
  );
};

const FormItem = styled(Form.Item)`
  &.ant-form-item {
    padding: 5px 30px;
  }
`;
